<template lang="html">
	<div class="">
		<div class="">
			<div class="">
				<!--<vx-card :title="'Solicitante: '+email" class="">-->
				<vx-card>
					<div class="row w-full">
						<div class="col-md-4 pt-4">
							<span class="span-placeholder">Nombre</span>
							<input class="vs-input" v-validate="'required|alpha_spaces'" v-model="nombre" v-on:keypress="soloLetras($event)" name="nombres" maxlength="30" />
							<span class="text-danger span-placeholder" v-show="errors.has('nombres')">Campo requerido</span>
						</div>

            <div class="col-md-4 pt-4">
							<span class="span-placeholder">Segundo nombre</span>
							<input class="vs-input" v-validate="'alpha_spaces'" v-model="segundoNombre" v-on:keypress="soloLetras($event)" name="segundoNombre" maxlength="30"/>
						</div>

						<div class="col-md-4 pt-4">
							<span class="span-placeholder">Apellido Paterno</span>
							<input class="vs-input" v-validate="'required|alpha_spaces'" v-model="apellidoPaterno" v-on:keypress="soloLetras($event)" name="apellidoPaterno" maxlength="30"/>
							<span class="text-danger span-placeholder" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
						</div>

            <div class="col-md-4 pt-4">
							<span class="span-placeholder">Apellido Materno</span>
							<input class="vs-input" v-validate="'required|alpha_spaces'" v-model="apellidoMaterno" v-on:keypress="soloLetras($event)" name="apellidoMaterno" maxlength="30"/>
							<span class="text-danger span-placeholder" v-show="errors.has('apellidoMaterno')">Campo requerido</span>
						</div>

						<div class="col-md-4 pt-4">
							<div class="form-group">
								<span class="formGroupExampleInput span-placeholder">Código país</span>
								<select name="cars" id="codigo" v-model="codigoSeleccionado" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2)" aria-placeholder="Seleccionar una opción" @change="actualizaCodigo()">
									<option :value="tipo.Lada" v-for="tipo in codigoPaises" :key="tipo.Id">{{ tipo.Pais }}</option>
								</select>
							</div>
						</div>

						<div class="col-md-4 pt-4">
							<span class="formGroupExampleInput span-placeholder">Teléfono celular</span>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="basic-addon1">{{ codigoSeleccionado }}</span>
								</div>
								<input type="text" class="vs-input" v-model="telefono" minlength="10" maxlength="10" placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="isNumber($event)" />
							</div>
						</div>

          	<div class="col-md-4 pt-4">
							<span class="span-placeholder">Correo electronico</span>
							<input class="vs-input" v-model="email" name="email" maxlength="30"/>
						</div>
						<div class="col-md-4 pt-4">
							<span class="span-placeholder">CURP</span>
							<input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />
							<span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
						</div>

						<div class="col-md-4 pt-4">
							<span class="span-placeholder">RFC</span>
							<input class="vs-input" v-model="rfc" name="rfc" v-validate="'min:13|max:13'" v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
							<span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
						</div>

            <div class="col-md-4 pt-4">
              <span class="span-placeholder" for="sexo">Sexo</span>
              <div class="col-md-12 mt-2 alinear">
                <div class="mr-4 alinear">
                  <div class="span-placeholder mr-2">Hombre</div>
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="H"></vs-radio>
                </div>
                <div class="ml-4 alinear">
                  <div class="span-placeholder mr-2">Mujer</div>
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="M"></vs-radio>
                </div>
              </div>
            </div>

						<div class="col-md-4 pt-4">
							<span class="span-placeholder">Fecha de nacimiento</span>
							<input class="vs-input" type="date" v-model="fechaNacimiento" name="fechaNacimiento" :max="getCurrentDate()" />
							<span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
						</div>

            <div class="col-md-4 pt-4" v-if="!this.isBuroCredito">
							<span class="span-placeholder">Nacionalidad</span>
							<!-- <input class="vs-input" type="text" v-model="nacionalidad" name="nacionalidad" /> -->
              <select class="vs-input" v-model="nacionalidad" name="nacionalidad">
                <option :value="''">Seleccione...</option>
                <option :value="tipo.Nombre" v-for="tipo in nacionalidades">{{ tipo.Nombre }}</option>
              </select>
							<span class="text-danger span-placeholder" v-show="errors.has('nacionalidad')">Campo requerido</span>
						</div>

            <div class="col-md-4 pt-4" v-if="this.isBuroCredito">
							<span class="span-placeholder">Número de cuenta</span>
							<input class="vs-input" v-model="numeroCuenta" name="numeroCuenta" v-validate="'min:19|max:19'" v-mask="'####-####-####-####'"/>
							<span class="text-danger span-placeholder" v-show="errors.has('numeroCuenta')">Campo requerido</span>
						</div>

            <div class="col-md-4 pt-4" v-if="this.isBuroCredito">
							<span class="span-placeholder">Régimen matrimonial</span>
              <select class="vs-input" v-model="regimenMatrimonial" name="regimenMatrimonial">
                <option :value="''">Seleccione...</option>
                <option :value="regimen" v-for="regimen in regimenMatrimoniales">{{ regimen }}</option>
              </select>
							<span class="text-danger span-placeholder" v-show="errors.has('regimenMatrimonial')">Campo requerido</span>
						</div>
					</div>

					<br />

					<div class="row w-full">
						<div class="col-md-12 text-end">
							<button class="btn btn-second" @click="agregaConyuge()" :disabled="!validate">Siguiente</button>
						</div>
					</div>
				</vx-card>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'
export default {
  components:{
    'v-select': vSelect,
  },
  props: {
    isBuroCredito: {
			type: Boolean,
			required: false,
    }
  },
  data(){
		return{
      maskRfc: ['AAAA999999&&&'],
			nacionalidades: [],
      conyuge:{
        participaCredito:false,
        email:'',
        nombre:'',
        segundoNombre:'',
        apellidoPaterno:'',
        apellidoMaterno:'',
        curp:'',
        rfc:'',
        esConyugue:true,
        seraCoPropietario:false,
        consolidaIngresos:false,
        parentesco:1,
      },
      coacreditado:{
        email:'',
        email2:'',
        email3:'',//existe coacreditado
        esConyugue:false,
        seraCoPropietario:true,
        consolidaIngresos:true,
        parentesco:'',
        participaCredito:true,
      },
      id:'',
      key:'',
      email:'',
      nombre:'',
      segundoNombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      fechaNacimiento:'',
      ingresosSinImpuestos:'',
      IngresosConImpuestos:'',
      estadoCivil:'',

      estadosCiviles:[],
      rfc:'',
      curp:'',
      sexo:'',
      calle:'',
      numeroInterior:'',
      numeroExterior:'',
      colonia:'',
      municipio:'',
      ciudad:'',
      nacionalidad:'',
      estado:'',
      codigoPostal:'',
      estados:[],
      states: ['AGUASCALIENTES','BAJA CALIFORNIA NORTE','BAJA CALIFORNIA SUR','CAMPECHE',
      'CHIAPAS','CHIHUAHUA','COAHUILA','COLIMA','CIUDAD DE MEXICO','DURANGO','ESTADO DE MÉXICO',
      'GUANAJUATO','GUERRERO','HIDALGO','JALISCO','MICHOACAN','MORELOS','NAYARIT','NUEVO LEON',
      'OAXACA','PUEBLA','QUERETARO','QUINTANA ROO','SAN LUIS POTOSI','SINALOA','SONORA','TABASCO',
      'TAMAULIPAS','TLAXCALA','VERACRUZ','YUCATAN','ZACATECAS'],
      parentescos:[],

      regimenMatrimoniales:[
        'Usuario autorizado',
        'Individual',
        'Mancomunado'
      ],
      numeroCuenta: '',
      regimenMatrimonial:'',

      nacionalidades:[
        { label: 'MEXICANA',  value: 'MEXICANO' },
        { label: 'EXTRANJERA',  value: 'EXTRANJERO' }
      ],
      codigoSeleccionado:'52',
      codigoPaises:[],
      emailCasoNuevo:{'bandera':'', 'Email':'', 'tel': '', 'Id':''},
      bancos:[],
      esquemas:[],
      plazos:[],
      programas:[],   
      broker: ''   
    }
  },
  computed:{
    validate: function () {      
      if ( this.isBuroCredito ) {

        if (
          !this.email ||
          !this.nombre ||
          !this.apellidoPaterno ||
          !this.apellidoMaterno ||
          !this.telefono ||
          !this.fechaNacimiento ||
          !this.rfc ||
          !this.curp ||
          !this.sexo ||
          !this.numeroCuenta ||
          !this.regimenMatrimonial
        )
        return false

      }

      if (this.estadoCivil == 1 || this.estadoCivil==4) {
        return this.conyuge.email!='' && this.conyuge.nombre!='' &&
        this.conyuge.apellidoPaterno!='' && this.conyuge.apellidoMaterno!=''
      }else {
        return true
      }
    },
    validate2: function () {
      return this.coacreditado.email != '' && this.coacreditado.parentesco != ''
    }
  },
  watch:{
    estadoCivil:function(){
      if (this.estadoCivil == 2 || this.estadoCivil== 3 || this.estadoCivil== 5 || this.estadoCivil== 6) {
        this.regimenMatrimonial=''
        this.conyuge.participaCredito=false
        this.conyuge.email=''
        this.conyuge.nombre=''
        this.conyuge.apellidoPaterno=''
        this.conyuge.apellidoMaterno=''
        this.conyuge.curp=''
        this.conyuge.rfc=''
        this.conyuge.esConyugue=true
        this.conyuge.seraCoPropietario=true
        this.conyuge.consolidaIngresos=true
        this.conyuge.parentesco=1
        this.conyuge.fechaNacimiento=''
        this.conyuge.sexo=''
        this.conyuge.nacionalidad=''
        this.conyuge.paisRecidencia=''
      }
    }
  },
  mounted(){


		this.getNacionalidades()
    if(!this.$route.params.id || (this.$route.params.id && this.$route.params.email)){

        this.getApikey()
        this.getEstados()
        this.getEstadosCiviles()
        this.getListaRegimenes()
        this.getListaParentescos()
        this.getListaCodigoPaises() //sms

          if(this.$route.params.email){
          
            this.email=this.$route.params.email

            this.getApikey()
            this.dameSolicitante()
            this.getEstados()
            this.getEstadosCiviles()
            this.getListaRegimenes()
            this.getListaParentescos()
            this.getListaCodigoPaises() //sms
          }

    }else{
        this.id=this.$route.params.id
        this.getApikey()
        this.dameCaso()
        this.getEstados()
        this.getEstadosCiviles()
        this.getListaRegimenes()
        this.getListaParentescos()
        this.getListaCodigoPaises() //sms
    }    

  },
  methods:{
    /*solorfc: function(event) {
      var regex = new RegExp("([A-Z,Ñ,&,a-z,ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
      /*const regex = /^([A-Z,Ñ,&,a-z,ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})$/
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const matches = regex.test(this.datosPersonales.rfc);
      if(matches){
        this.rfcValido=false
      }else{
        this.rfcValido=true
      }*/

   /* },
    alfanumericos: function(event) {
        var regex = new RegExp("^[ña-zÑA-Z0-9 ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
    },*/
    getCurrentDate() {
      //const fecha = new Date("March 1, 2023");
      const fecha = new Date();

      // se obtiene la fecha actual dia, mes, año
      var day = fecha.getDate()
      var Month = fecha.getMonth()
      var year = fecha.getFullYear()

      //si dia es 1 se toma el ultimo dia del mes anterior
      if (day == 1) {
        let newMonth = fecha.getMonth() - 1                         // mes anterior
        let fechaAux = new Date(year, newMonth + 1, 0); 
        day = fechaAux.getDate()                                    // ultimo dia del mes anterior        
        Month = newMonth           
      }else{
        day = day - 1
      }        

      // formato 01, 02 ... 09 a los dias menores a 10
      if (day < 10) {
        day = "0"+day
      }

      // formato 01,02...08 a los meses menores a 10
      switch (Month) {
        case 0:
          Month = "01";
        break;
        case 1:
          Month = "02";
        break;
        case 2:
          Month = "03";
        break;
        case 3:
          Month = "04";
        break;
        case 4:
          Month = "05";
        break;
        case 5:
          Month = "06";
        break;
        case 6:
          Month = "07";
        break;
        case 7:
          Month = "08";
        break;
        case 8:
          Month = "09";
        break;
        case 9:
          Month = "10";
        break;
        case 10:
          Month = "11";
        break;
        case 11:
          Month = "12";
        break;
      
        default:
          break;
      }

      let fechaActual = +year+"-"+Month+"-"+day      

      return fechaActual      

    },
    getNacionalidades() {
			let nacionalidadesList = [];
			let index = 3;
			for (const nacionalidad of nacionalidades) {
				if (nacionalidad.Nombre === "MEXICANO (A)") {
					nacionalidadesList[0] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "NORTEAMERICANO (A)") {
					nacionalidadesList[1] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "CANADIENSE") {
					nacionalidadesList[2] = nacionalidad;
					continue;
				}
				nacionalidadesList[index] = nacionalidad;
				index ++;
			}
			this.nacionalidades = nacionalidadesList;
		},
    soloLetras: function(event) {
        var regex = new RegExp("^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
          }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault()
			} else {
				return true
			}
    },
     actualizaCodigo(){
      this.codigoPais=this.codigoSeleccionado
    },
    getListaCodigoPaises(){
      var objRequestListaPais = {
				strApiKey: this.key,
				strMetodo: 'ListaPaisLada',
			}
			this.$axios.post('/',objRequestListaPais,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.codigoPaises=response.data.objContenido
            //console.log(this.codigoPaises)
					}
				}
			).catch(function (error) {
        this.makeToast(error)
			})
    },
    dameCoacreditadoRegistrado(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.coacreditado.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.coacreditado.email3=this.coacreditado.email
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraCoacreditado(){
      let self= this
      if (this.coacreditado.email == this.conyuge.email) {
        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.conyuge.email,
            Nombres: this.conyuge.nombre,
            ApellidoPaterno: this.conyuge.apellidoPaterno,
            ApellidoMaterno: this.conyuge.apellidoMaterno,
            TelefonoCelular: '',
            FechaNacimiento: '',
          }
        }
        this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.agregarCoacreditado()
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }

        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }else if(this.coacreditado.email3!=''){
        this.agregarCoacreditado()
      }else {
        if (this.coacreditado.email2) {
          this.agregarCoacreditado()
        }else {
          let objRequestRegistraSolicitante = {
            strApiKey: this.key,
            strMetodo: 'RegistraSolicitante',
            objSolicitante: {
              EMail: this.coacreditado.email,
              Nombres: '',
              ApellidoPaterno: '',
              ApellidoMaterno: '',
              TelefonoCelular: '',
              FechaNacimiento: '',
            }
          }
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.agregarCoacreditado()
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        }
      }
    },
    agregarCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.coacreditado.email,
          DatosCoacreditado:{
            EsConyuge:this.coacreditado.esConyugue,
            SeraCoPropietario:this.coacreditado.seraCoPropietario,
            ConsolidaIngresos:this.coacreditado.consolidaIngresos,
            Parentesco:this.coacreditado.parentesco,
            ParticipaCredito:this.coacreditado.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyugeAsCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.conyuge.email,
          DatosCoacreditado:{
            EsConyuge:this.conyuge.esConyugue,
            SeraCoPropietario:this.conyuge.seraCoPropietario,
            ConsolidaIngresos:this.conyuge.consolidaIngresos,
            Parentesco:this.conyuge.parentesco,
            ParticipaCredito:this.conyuge.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyuge(){

      // this.emailCasoNuevo.bandera= 'Personales'
      // this.emailCasoNuevo.Email= this.email
      // this.emailCasoNuevo.tel= this.telefono
            
      // this.$emit("bandera",  this.emailCasoNuevo)   
      this.actualizarInfo()
      
    },
    actualizarInfo(){
      let self= this
      let sexo
      let buro_cuenta = ''

      if(this.sexo== 'H'){
        sexo=1
      }else{
        sexo=2
      }

      this.isBuroCredito ?  buro_cuenta = this.numeroCuenta.replace(/-/g, '') : ''
      

      let objRequestRegistraSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          Nombres: this.nombre,
          SegundoNombre: this.segundoNombre,
          ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
          TelefonoCelular: this.telefono,
          Lada: this.codigoSeleccionado, //agregar sms
          FechaNacimiento: this.fechaNacimiento,
          Rfc: this.rfc,
          Curp: this.curp,
      		Sexo:sexo,
          Nacionalidad: {
            name: this.nacionalidad,
            noSanitiza: true,
          },

          BuroNumeroCuenta: buro_cuenta,
          BuroRegimenMatrimonial: this.regimenMatrimonial
        }
      }

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0) {
                if ( !this.$route.params.id ) {
                  if(!this.$route.params.email){
                    this.RegistraNuevoCaso()
                  }
                    
                }
                else {

                  this.emailCasoNuevo.bandera= 'Personales'
                  this.emailCasoNuevo.Email= this.email
                  this.emailCasoNuevo.tel= this.telefono
                  this.emailCasoNuevo.Id= this.id

                  this.$emit("bandera",  this.emailCasoNuevo)

                }

                this.$vs.notify({
                  title:`Datos guardados exitosamente`,
                  position:'top-right'
                })
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        } else {
          this.$vs.notify({
            title:'Ocurrio un error',
            text:'Datos incompletos',
            color:'danger',
            position:'top-right'
          })
        }
      })
    },
    RegistraNuevoCaso(){
      let self=this

      this.bancos = [2,4,6,10,11]
      this.esquemas = [2]
      this.plazos = [20]
      this.programas = [1]

      let objRequestRegistraCaso1= {}
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id: parseInt(this.id),
            ValorInmueble : parseFloat(1000000),
            MontoSolicitado : parseFloat(800000),
            Plazos : this.plazos,
            Solicitante: this.email,
            Broker: this.broker,
            Destino : 5,
            Esquemas:this.esquemas,
            Bancos:this.bancos,
            Programas:this.programas,
            PerfilCliente : 2,
            VersionOrigen: 'Web Buro Credito',
          }
        
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.id=response.data.objContenido.Id

            this.emailCasoNuevo.bandera= 'Personales'
            this.emailCasoNuevo.Email= this.email
            this.emailCasoNuevo.tel= this.telefono
            this.emailCasoNuevo.Id= this.id

          //  alert('TELEFONO'+this.emailCasoNuevo.Id)
            
            this.$emit("bandera",  this.emailCasoNuevo)

            this.dameCaso()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
            
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            let coacreditado=response.data.objContenido.Coacreditado
            if (coacreditado.length) {
              if (coacreditado[0].EsConyuge && coacreditado[0].ParticipaCredito) {
                this.conyuge.esConyugue=coacreditado[0].EsConyuge
                this.conyuge.email=coacreditado[0].EMail
                this.conyuge.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.conyuge.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.conyuge.parentesco=coacreditado[0].Parentesco
                this.conyuge.participaCredito=coacreditado[0].ParticipaCredito
              }else {
                this.coacreditado.esConyugue=coacreditado[0].EsConyuge
                this.coacreditado.email2=coacreditado[0].EMail
                this.coacreditado.email=coacreditado[0].EMail
                this.coacreditado.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.coacreditado.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.coacreditado.parentesco=coacreditado[0].Parentesco
                this.coacreditado.participaCredito=coacreditado[0].ParticipaCredito
              }
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso 2',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameConyuge(email){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let conyuge =response.data.objContenido
            this.conyuge.nombre= conyuge.Nombres
            this.conyuge.email= conyuge.EMail
            this.conyuge.apellidoPaterno= conyuge.ApellidoPaterno
            this.conyuge.apellidoMaterno= conyuge.ApellidoMaterno
            this.conyuge.rfc=conyuge.Rfc
            this.conyuge.curp = conyuge.Curp
            this.conyuge.fechaNacimiento= conyuge.FechaNacimiento
            if(conyuge.Sexo== 1){
              this.conyuge.sexo='H'
            }else if (conyuge.Sexo== 2) {
              this.conyuge.sexo='M'
            }
            this.conyuge.nacionalidad= conyuge.Nacionalidad
            this.conyuge.paisRecidencia= conyuge.PaisRecidencia

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){

      if(this.$route.params.id) {
        this.id = this.$route.params.id;
      }

      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            
            let solicitante =response.data.objContenido
            this.nombre= solicitante.Nombres
            this.segundoNombre= solicitante.SegundoNombre
          
            this.apellidoPaterno= solicitante.ApellidoPaterno
            this.apellidoMaterno= solicitante.ApellidoMaterno
            this.telefono= solicitante.TelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')
            this.fechaNacimiento= solicitante.FechaNacimiento
            this.rfc=solicitante.Rfc
            if(solicitante.Sexo== 1){
              this.sexo='H'
            }else if (solicitante.Sexo== 2) {
              this.sexo='M'
            }
            this.calle = solicitante.Calle
            this.numeroExterior = solicitante.NumeroExterior
            this.numeroInterior = solicitante.NumeroInterior
            this.colonia = solicitante.Colonia
            this.ciudad = solicitante.Ciudad
            this.municipio = solicitante.Municipio
            this.curp = solicitante.Curp
            this.codigoPostal = solicitante.CodigoPostal
            this.estado = solicitante.Estado
            this.estadoCivil=solicitante.EstadoCivil

            this.numeroCuenta = solicitante.BuroNumeroCuenta
            this.regimenMatrimonial = this.isBuroCredito ? solicitante.BuroRegimenMatrimonial : solicitante.RegimenMatrimonial

            this.nacionalidad=solicitante.Nacionalidad

            // console.log({ regimenMatrimonial: solicitante.RegimenMatrimonial })

            if (solicitante.Conyuge != null && (this.estadoCivil== 1 || this.estadoCivil== 4)) {
              this.dameConyuge(solicitante.Conyuge)
            }
            //this.codigoSeleccionado=solicitante.Lada
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;        
        this.broker = JSON.parse(localStorage.getItem('userInfo')).EMail
;
      }
    },
    getEstadosCiviles(){
      let self= this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaEstadosCiviles',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estadosCiviles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstadosCiviles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getStateName(id){
      return this.states[id-1]
    },
    getStateNumber(stateName){
      for (var i = 0; i < this.states.length; i++) {
        if(stateName == this.states[i]){
          return i+1
        }
      }
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaRegimenes(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaRegimenesMatrimoniales',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.regimenes=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaRegimenesMatrimoniales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

  }
}
</script>

<style lang="css">
.red{
  color: red;
}
.input-group{
flex-wrap: nowrap;
}
.input-group-text{
  margin-top: 10px;
  height: 40px;
}
</style>