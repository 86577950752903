<template lang="html">
  <div class="vista">
    <vx-card title="Buro de Crédito" class="" v-if="mostrarBoton"> <!--Agregar sms v-if="mostrarBoton"-->
      <vs-alert color="danger" title="Completa tu Información" :active="!completeInfo">
        Ve al apartado de información personal y completa los datos generales para poder consultar el buró de crédito.
      </vs-alert>
      <br>
      <!--<div class="row w-full" >
            <div class="col-md-8 alinear">
              <div class="col-md-7">
                <span class="" for="">¿Cuenta con una tarjeta de crédito vigente?</span>
              </div>
              <div class="col-md-2">  
                <vs-switch v-model="tarjeta"/> 
              </div>  
              <div class="col-md-4"  v-if="tarjeta">
              <span class="span-placeholder">Ùltimos 4 digitos</span>
              <input class="vs-input" v-model="digitos" name="name" />
              </div> 
            </div>
          </div>
          <div class="row w-full">  
            <div class="col-md-8 alinear">
              <div class="col-md-7">
                  <span class="" for="">¿Cuenta con un crédito hipotecario vigente?</span>
              </div>
              <div class="col-md-2">      
                  <vs-switch v-model="creditoHipotecario"/>
              </div>
            </div>
          </div>
          <div class="row w-full">  
            <div class="col-md-8 alinear" >
              <div class="col-md-7">
                  <span for="">¿Ha tenido un crédito automotriz en los últimos 24 meses?</span>
              </div>
              <div class="col-md-2">    
                  <vs-switch v-model="creditoAuto"/>
              </div>
            </div>
          </div>-->
      <br>
      <button class="btn btn-second-line" @click="openConfirm()" :disabled="isDisabled" accept-text="aceptar">Consultar Buro de crédito</button><!-- se quita validación de campos :disabled="!completeInfo"-->
      <!-- @click="openConfirm()" para sms-->
      <!-- @click="actualizarInfo()" -->
    </vx-card>
    <vx-card title="" class="" v-if="!mostrarBoton">
      <div class="confirmacion-phone text-center">
        <p>Escribe el código que recibiste en tu teléfono</p>
        <input id="uno" type="text" v-model="numUno" maxlength="1" minlength="1" class="mb-2 text-center" placeholder="" @keyup="cambiarInput()">
        <input id="dos" type="text" v-model="numDos" maxlength="1" minlength="1" class="mb-2 text-center" placeholder="" @keyup="cambiarInput()">
        <input id="tres" type="text" v-model="numTres" maxlength="1" minlength="1" class="mb-2 text-center" placeholder="" @keyup="cambiarInput()">
        <input id="cuatro" type="text" v-model="numCuatro" maxlength="1" minlength="1" class="mb-2 text-center" placeholder="">
        <a @click="generarCodigo()" class="d-block blue text-center mb-4">Reenviar código</a>
        <div class="d-flex justify-content-center justify-content-md-end">
          <a class="mr-2 btn-back" @click="regresar()">Regresar</a>
          <vs-button @click="validaCodigo()" :disabled="!infoCompleta" color="primary">Confirmar número</vs-button>
          <div class="alert alert-success alert-margin" @click="closeAlert('exito')" role="alert " :class="alertExito? '' : 'ocultarMensaje' ">{{mensajeExito}}</div>
          <div class="alert alert-danger" @click="closeAlert('error')" role="alert" :class="alertError? '' : 'ocultarMensaje' ">{{mensajeError}}</div>
        </div>
        <!--<a  class="close"><img src="../../../assets/images/icon-close.svg" width="15px" alt="close"></a>  -->
      </div>
    </vx-card><!-- Agregar sms -->
  </div>
</template>

<script>
export default {
	props: {
		emailN: {
			type: String,
			required: false,
		},
		telN: {
			type: String,
			required: false,
		},
		IdN: {
			type: Number,
			required: false,
		},
		isBuroCredito: {
			type: Boolean,
			required: false,
    },
		isDisabled: {
			type: Boolean,
			required: false
		}
	},
	data() {
		return {
			completeInfo: false,
			id: '',
			key: '',
			email: '',
			tarjeta: '',
			digitos: '',
			creditoHipotecario: '',
			creditoAuto: '',
			popupActive: false,
			creditos: [],
			score: '',
			activeConfirm: false,
			mensajeError: '',
			mensajeExito: '',
			alertExito: false,
			alertError: false,
			mostrarBoton: true,
			ladaPais: '',
			telefonoCelular: '',
			numUno: '',
			numDos: '',
			numTres: '',
			numCuatro: '',
			emailCasoNuevo: { bandera: '', Email: '', tel: '', Id: '' },
			// isDisabled: true
		}
	},
	computed: {
		infoCompleta() {
			return this.numUno.length > 0 && this.numDos.length > 0 && this.numTres.length > 0 && this.numCuatro.length > 0
		},
	},
	mounted() {
		// alert('hola')
		if (!this.$route.params.id) {
			this.getApikey()

			if (this.$route.params.email) {
				this.email = this.$route.params.email
				this.getApikey()
				this.dameSolicitante()
			}
		} else {
			this.id = this.$route.params.id
			this.getApikey()
			this.dameCaso()
		}
	},
	methods: {
		closeAlert(alerta) {
			switch (alerta) {
				case 'exito':
					this.alertExito = false
					break
				case 'error':
					this.alertError = false
					break
				default:
					break
			}
		},
		consultarSolicitanteyGenerarSms() {
			//if(this.ladaPais!=null && this.ladaPais!=''){alert(this.ladaPais)
			this.dameSolicitante()

			setTimeout(() => this.generarCodigo(), 2000)
			/*}else{
        this.$vs.notify({
              title:'Error la lada del pais esta vacía',
              text:'Debes agregar la lada del país de tu número celular',
              color:'danger',
              position:'top-right'
            })
      }*/
		},
		regresar() {
			this.mostrarBoton = true
			this.completeInfo = true
		},
		cambiarInput() {
			//var focusedElement = document.activeElement;
			//console.log(focusedElement.id);
			if (this.numUno.length > 0) {
				document.getElementById('dos').focus()
			}
			if (this.numUno.length > 0 && this.numDos.length > 0) {
				document.getElementById('tres').focus()
			}
			if (this.numUno.length > 0 && this.numDos.length > 0 && this.numTres.length > 0) {
				document.getElementById('cuatro').focus()
			}
		},
		validaCodigo() {
			//console.log("variable isBuro "+ this.isBuroCredito)
			let self = this
			this.codigoVerificacion = this.numUno + this.numDos + this.numTres + this.numCuatro
			var objGeneraCodigo = {
				Codigo: this.codigoVerificacion,
				numero: this.ladaPais + this.telefonoCelular,
			}

			this.$axios
				.post('https://cotizador.socasesores.com/EnvioSms/ValidaSms.php', objGeneraCodigo, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					//if(response.intRespuesta==0){
					var respuestaHubspot = JSON.parse(response.data.objRespuesta)
					if (respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Número telefónico validado con éxito') {
						
						this.mensajeExito = respuestaHubspot.Mensaje
						this.alertExito = true
						setTimeout(() => (this.alertExito = false), 5000)
						this.mostrarBoton = true
						this.completeInfo = true
						this.numUno = ''
						this.numDos = ''
						this.numTres = ''
						this.numCuatro = ''
						//this.consultar()
						//this.$router.push('/reportesCredito')
						//this.$router.push('/contactar-asesor-folio').catch(() => {})
						this.isBuroCredito ? this.cosultarBuroCredito() : this.consultar()


					} else if (respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Código inválido o expiró') {
						this.alertError = true
						this.mensajeError = respuestaHubspot.Mensaje
						setTimeout(() => (this.alertError = false), 5000)
					} else if (respuestaHubspot.Message) {
						this.alertError = true
						this.mensajeError = respuestaHubspot.Message
						setTimeout(() => (this.alertError = false), 5000)
					} else if (respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Error') {
						this.alertError = true
						this.mensajeError = respuestaHubspot.Mensaje
						setTimeout(() => (this.alertError = false), 5000)
					} else if (respuestaHubspot.Mensaje !== '') {
						this.alertError = true
						this.mensajeError = respuestaHubspot.Mensaje
						setTimeout(() => (this.alertError = false), 5000)
					}
				})
				.catch(function (error) {
					this.$vs.notify({
						title: 'Ocurrio un errros en ValidaSms',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		generarCodigo() {
			/*console.log("buro" + this.isBuroCredito)
			return */
			 //return this.isBuroCredito ? this.cosultarBuroCredito() : this.consultar()			 

			this.mostrarBoton = false
			this.mensajeExito = 'En breve te llegará un código a tu teléfono, Este tiene una validez de 5 minutos.”'
			//this.alertExito=true
			//let self=this
			var objGeneraCodigo = {
				metodo: 'Generar',
				numero: this.ladaPais + this.telefonoCelular,
			}

			this.$axios
				.post('https://cotizador.socasesores.com/EnvioSms/GeneraSms.php', objGeneraCodigo, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {

					var respuestaHubspot = JSON.parse(response.data.objRespuesta)
					console.log(respuestaHubspot)
					if (respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Teléfono validado.') {
						this.mensajeExito = respuestaHubspot.Mensaje
						this.alertExito = true
						setTimeout(() => (this.alertExito = false), 5000)
						this.mostrarBoton = true
						this.completeInfo = true
						this.numUno = ''
						this.numDos = ''
						this.numTres = ''
						this.numCuatro = ''

						this.isBuroCredito ? this.cosultarBuroCredito() : this.consultar()

					} else if (respuestaHubspot.Message == 'Authorization has been denied for this request.') {
						this.alertError = true
						this.mensajeError = respuestaHubspot.Mensaje
						setTimeout(() => (this.alertError = false), 5000)
					} else if (respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Mensaje SMS enviado.') {
						this.alertExito = true
						setTimeout(() => (this.alertExito = false), 5000)
						this.mostrarBoton = false
					} else if (respuestaHubspot.Mensaje !== '') {
						this.$vs.notify({
							title: 'Ocurrio un error en EnvioSms',
							text: respuestaHubspot.Mensaje,
							color: 'danger',
							position: 'top-right',
						})
						this.alertError = true
						this.mensajeError = respuestaHubspot.Mensaje
						setTimeout(() => (this.alertError = false), 5000)
					}
				})
				.catch(function (error) {
					this.$vs.notify({
						title: 'Ocurrio un errros en EnvioSms',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		openConfirm() {
			this.$vs.dialog({
				type: 'confirm',
				color: 'primary',
				title: `Consultar Buró de Crédito`,
				text: '¿Estás seguro que deseas realizar la consulta?',
				accept: this.consultarSolicitanteyGenerarSms,
				acceptText: 'Aceptar',
				cancelText: 'Cancelar', //agregar sms
			})
		},
		actualizarInfo() {
			/*let self=this
      this.$vs.loading({
        container: '#main',
        scale: 0.6
      })*/
			let objRequestRegistraSolicitante = {
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.email,
					DigitosTarjetaCredito: this.digitos,
					CreditoHipotecarioActual: this.creditoHipotecario,
					CreditoAutomotirzReciente: this.creditoAuto,
				},
			}
			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.consultar()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

		consultar() { // Consulta para circulo de credito
			this.$vs.loading.close('#main > .con-vs-loading')
			this.$router.push('/reportesCredito')
			window.open('https://cotizador.socasesores.com/app/buro/' + this.email, '_blank')
		},

		cosultarBuroCredito () {
			this.$vs.loading.close('#main > .con-vs-loading')
			this.$router.push('/buroCredito')
			window.open('https://cotizador.socasesores.com/app/reporteBuroCredito/' + this.IdN, '_blank')
			//window.open('http://localhost:8085//app/reporteBuroCredito/' + this.IdN, '_blank')
		},

		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.email = response.data.objContenido.Solicitante
						this.dameSolicitante()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameSolicitante() {
			let self = this
			if (!this.email) {
				this.email = this.emailN
			}
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.emailN,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let solicitante = response.data.objContenido
						//campos para sms
						this.telefonoCelular = solicitante.TelefonoCelular
						this.ladaPais = solicitante.Lada
						this.completeInfo = solicitante.Nombres != '' && solicitante.ApellidoPaterno != '' && solicitante.ApellidoMaterno != '' && solicitante.Rfc != '' && solicitante.FechaNacimiento != '' && solicitante.Calle != '' && solicitante.NumeroExterior != '' && solicitante.Colonia != '' && solicitante.Ciudad != '' && solicitante.CodigoPostal != '' && solicitante.Estado != '' && solicitante.TelefonoCelular != ''

						// console.log('this.completeInfo :>> ', this.completeInfo)

					} else {
						// this.$vs.notify({
						// 	title: 'Ocurrio un error en DameSolicitante',
						// 	text: response.data.strError,
						// 	color: 'danger',
						// 	position: 'top-right',
						// })
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
			}
		},
	},
}
</script>

<style lang="css" scoped>
.selectExample{
  width: 150%;}

.confirmacion-phone{
    background: #F1F2F7 0% 0% no-repeat padding-box;
    border: 1px solid #00000033;
    border-radius: 4px;
    padding: 4rem 1rem 2rem 1rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.confirmacion-phone input{
    max-width: 40px;
    margin: 0 auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
}
.confirmacion-phone p{
    color: #212529 !important;
    font-weight: bold !important;
    visibility: visible; 
    opacity: 1;
    display: block;
    font-size: 14px !important;
    background-color: unset;
    text-align: center;
}
.confirmacion-phone a{
    color: #829494;
}
.confirmacion-phone a.blue{
    color: #2AB5DF;
}
.confirmacion-phone{
    position: relative;
}
.close{
    position: absolute;
    right: 10px;
    top: 5px;
}
.btn-back{
    background-color: transparent !important;
    color: #6c757d;
    font-size: 17px !important;
    width: 275px !important;
    height: 44px;
    border: none;

}
.ocultarMensaje{
  display: none !important;
}
.alert {
    max-width: 300px;
    bottom: 63px;
    right: 3.2rem;
    position: absolute;
    padding: 0.75rem 1.25rem;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.vs-component.con-vs-dialog.vs-dialog-primary {
    top: 120px !important;
}

</style>
